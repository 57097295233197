import React from 'react';
import { motion } from 'framer-motion';
import Page22 from '../Assets/Page22.png';
import Page22Text from '../Assets/Page22Text.png';


const Page22Component = () => {
    const pathVariants = {
        hidden: {
            pathLength: 0,
            opacity: 0
        },
        visible: {
            pathLength: 1,
            opacity: 1,
            transition: {
                duration: 3, // Duration of the animation
                ease: "easeInOut"
            }
        }
    };
    const textAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: {
            delay: 3, // Set this delay to after all other animations
            duration: 2
        }
    };

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page22} alt="Page 22" style={{ width: '100%', height: 'auto' }} />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 612"
            style={{ position: 'absolute', top: '0', left: '0%', width: '100%', height: '100%' }}>
            <defs>
                <style>
                    {`
                    .cls-1 { mask: url(#mask); }
                    .cls-2 { fill: #f9bb65; }
                    .cls-3 { fill: #fff; stroke-width: 1px; }
                    `} 
                </style>
                <mask id="mask" x="0" y="-31.29" width="396" height="644.29" maskUnits="userSpaceOnUse">
                    <rect class="cls-3" width="396" height="612"/>
                </mask>
            </defs>
            <g class="cls-1">
                <motion.path
                    class="cls-2"
                    d="M42.87,0v612h-18.69V0h18.69M43.87-1h-20.69v614h20.69V-1h0Z"
                    variants={pathVariants}
                    initial="hidden"
                    animate="visible"
                />
            </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 612"
                style={{ position: 'absolute', top: '0', left: '0%', width: '100%', height: '100%' }}>
                <defs>
                    <style>
                        {`
                        .new-cls-1 { mask: url(#new-mask); }
                        .new-cls-2 { fill: #5b2d86; }
                        .new-cls-3 { fill: #fff; stroke-width: 0px; }
                        `}
                    </style>
                    <mask id="new-mask" x="0" y="-31.29" width="396" height="644.29" maskUnits="userSpaceOnUse">
                        <rect class="new-cls-3" width="396" height="612"/>
                    </mask>
                </defs>
                <g class="new-cls-1">
                    <motion.path
                    class="new-cls-2"
                    d="M49.86-13.82v612h-18.69V-13.82h18.69M50.86-14.82h-20.69v614h20.69V-14.82h0Z"
                    variants={pathVariants}
                    initial="hidden"
                    animate="visible"
                    />
                </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 612"
                style={{ position: 'absolute', top: '0', left: '0%', width: '100%', height: '100%' }}>
                <defs>
                    <style>
                        {`
                        .new-cls-1 { mask: url(#new-mask); }
                        .new-cls-4 { fill: #f37251; }
                        .new-cls-3 { fill: #fff; stroke-width: 0px; }
                        `}
                    </style>
                    <mask id="new-mask" x="0" y="-31.29" width="396" height="644.29" maskUnits="userSpaceOnUse">
                        <rect class="new-cls-3" width="396" height="612"/>
                    </mask>
                </defs>
                <g class="new-cls-1">
                    <motion.path
                    class="new-cls-4"
                    d="M62.56-30.29v612h-18.69V-30.29h18.69M63.56-31.29h-20.69v614h20.69V-31.29h0Z"
                    variants={pathVariants}
                    initial="hidden"
                    animate="visible"
                    />
                </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 612"
                style={{ position: 'absolute', top: '0', left: '0%', width: '100%', height: '100%' }}>
                <defs>
                    <style>
                        {`
                        .cls-6 {
                            fill: none;
                            stroke: #4a2c82;
                            stroke-miterlimit: 10;
                          }
                        `}
                    </style>
                    
                </defs>
                <g>
                    <motion.path
                    class="cls-6"
                    d="M199.95,452.13c3.93,14.8,7.87,29.59,11.8,44.39,4.92-4.67,9.83-9.35,14.75-14.02,3.43-3.26,7.51-6.76,12.22-6.26,6.6.69,9.97,8.61,16.1,11.15,1.53.63,3.18.91,4.73,1.48,6.61,2.44,10.04,9.55,12.83,16.03,2.81,6.53,5.62,13.06,8.42,19.59,1.63,3.78,4.7,8.32,8.66,7.19,3.24-.92,4.19-4.99,4.5-8.34,2.04-21.88,1.22-44.03-2.44-65.7"
                    variants={pathVariants}
                    initial="hidden"
                    animate="visible"
                    />
                    <motion.path
                    class="cls-6"
                    d="M196.58,165.87c9.14-7.08,15.84-17.24,18.75-28.42.55-2.1,1.26-4.58,3.32-5.26,1.25,7.98,3.46,15.8,6.55,23.26.9,2.18,2.34,4.66,4.7,4.76,1.93.08,3.5-1.55,4.43-3.24,1.58-2.89,2.09-6.23,3.31-9.29,1.22-3.06,3.57-6.06,6.83-6.46,1.94-.24,4.01.46,5.8-.31,1.49-.64,2.39-2.14,3.19-3.55,10.28-18.2,18.81-37.38,25.46-57.2,6.14,15.87,1.16,34.57,8.07,50.12,2.17,4.88,5.45,9.29,6.91,14.42,1.85,6.51.56,13.45-.73,20.09"
                    variants={pathVariants}
                    initial="hidden"
                    animate="visible"
                    />
                </g>
            </svg>
            <motion.img
                src={Page22Text}
                alt="Text"
                style={{ position: 'absolute', width: '100%', left: '0%', top: '0%'}}
                {...textAnimation} 
            />
        </div>
    );
};

export default Page22Component;
