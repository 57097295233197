import React from 'react';
import { motion } from 'framer-motion';
import Page20 from '../Assets/Page20.png';
import Page21 from '../Assets/Page21.png';

const Page20and21Component = () => {
    const pathVariants = {
        hidden: {
            pathLength: 0,
            opacity: 0
        },
        visible: {
            pathLength: 1,
            opacity: 1,
            transition: {
                duration: 5, // Duration of the animation
                ease: "easeOut"
            }
        }
    };

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div style={{ display: 'flex' }}>
                <img src={Page20} alt="Page 20" style={{ width: '50%', height: 'auto' }} />
                <img src={Page21} alt="Page 21" style={{ width: '50%', height: 'auto' }} />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 612"
                style={{ position: 'absolute', top: '0', left: '-25%', width: '100%', height: '100%' }}>
               <defs>
                <style>
                    {`
                    .cls-1 { fill: none; stroke: #fff5ec; stroke-miterlimit: 10;stroke-width: 2px;}
                    .cls-2 {mask: url(#mask);}
                    .cls-3 {fill: #fff;stroke-width: 0px;} 
                    `}
                </style>
            </defs>
                <g>
                    <motion.path
                        className="cls-1"
                        d="M239.3-87.74c9.01,51.68-19.88,107.64-67.23,130.22-11.8,5.63-24.48,9.34-36.02,15.49-11.53,6.16-22.25,15.43-26.08,27.94-1.63,5.32-1.83,11.42,1.12,16.13s9.73,7.18,14.39,4.15c6.5-4.24,3.7-15.43-3.17-19.03-6.88-3.6-15.25-1.89-22.68.39-39.17,11.99-72.95,36.72-106.61,60.08-33.66,23.36-69.21,46.25-109.5,53.65-40.29,7.4-86.73-4.25-109.62-38.23"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                    <motion.path
                        className="cls-1"
                        d="M48.98-7.24c.81.71,1.63,1.43,2.44,2.14,11.64,17.65,15.95,39.99,11.7,60.7-3.03,14.8-10.17,28.58-12.58,43.49-3.63,22.43,4.42,46.42,20.83,62.12,5.98,5.72,13.99,10.61,22.11,9.02,8.13-1.59,13.86-12.75,8.01-18.61-16.73-2.9-30.03,12.67-42.84,23.81-31.14,27.08-79.58,32.04-115.56,11.85"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                    <motion.path
                        className="cls-1"
                        d="M257.14-10.81c8.39,29.03-3.71,62.79-28.63,79.87-6.37,4.37-13.82,7.79-21.54,7.36s-15.53-5.62-16.97-13.21c-1.44-7.59,5.84-16.08,13.34-14.23,6.05,1.5,9.39,8.9,7.68,14.9-1.71,6-7.36,10.28-13.41,11.81-13.37,3.37-27.25-5.22-35.24-16.46-7.99-11.24-11.72-24.84-17.1-37.53s-13.35-25.39-26.03-30.8c-12.68-5.41-30.53.17-33.61,13.61"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 612"
                style={{ position: 'absolute', top: '0', left: '25%', width: '100%', height: '100%' }}>
               <defs>
                <style>
                    {`
                    .cls-1 { fill: none; stroke: #fff5ec; stroke-miterlimit: 10;stroke-width: 2px;}
                    .cls-2 {mask: url(#mask);}
                    .cls-3 {fill: #fff;stroke-width: 0px;} 
                    `}
                </style>
            </defs>
                <g>
                    <motion.path
                        className="cls-1"
                        d="M173.54,680.7c-9.01-51.68,19.88-107.64,67.23-130.22,11.8-5.63,24.48-9.34,36.02-15.49s22.25-15.43,26.08-27.94c1.63-5.32,1.83-11.42-1.12-16.13s-9.73-7.18-14.39-4.15c-6.5,4.24-3.7,15.43,3.17,19.03,6.88,3.6,15.25,1.89,22.68-.39,39.17-11.99,72.95-36.72,106.61-60.08,33.66-23.36,69.21-46.25,109.5-53.65,40.29-7.4,86.73,4.25,109.62,38.23"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                    <motion.path
                        className="cls-1"
                        d="M363.86,600.2c-.81-.71-1.63-1.43-2.44-2.14-11.64-17.65-15.95-39.99-11.7-60.7,3.03-14.8,10.17-28.58,12.58-43.49,3.63-22.43-4.42-46.42-20.83-62.12-5.98-5.72-13.99-10.61-22.11-9.02-8.13,1.59-13.86,12.75-8.01,18.61,16.73,2.9,30.03-12.67,42.84-23.81,31.14-27.08,79.58-32.04,115.56-11.85"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                    <motion.path
                        className="cls-1"
                        d="M155.7,603.77c-8.39-29.03,3.71-62.79,28.63-79.87,6.37-4.37,13.82-7.79,21.54-7.36s15.53,5.62,16.97,13.21-5.84,16.08-13.34,14.23c-6.05-1.5-9.39-8.9-7.68-14.9,1.71-6,7.36-10.28,13.41-11.81,13.37-3.37,27.25,5.22,35.24,16.46,7.99,11.24,11.72,24.84,17.1,37.53,5.38,12.69,13.35,25.39,26.03,30.8,12.68,5.41,30.53-.17,33.61-13.61"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                </g>
            </svg>
        </div>
    );
};

export default Page20and21Component;
