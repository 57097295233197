import {React} from 'react';
import './ContactUsPage.css';

const ContactUsPage = () => {
    return (
        <div className="contactus-container">
            <div className="contact-heading">
                <span style={{fontFamily:'inglesa-script-variable'}}>C</span>ONTACT US
            </div>
            <div className="contact-info">
                <div className="insta">
                    <div className="contact-pill" style={{width: '70%'}}>
                        Instagram
                    </div>
                    <span style={{fontSize:'1.5vw'}}>&gt; @luckyonezine</span>
                </div>
                <div className="email">
                    <div className="contact-pill" style={{width: '45%'}}>
                        Email
                    </div>
                    <span style={{fontSize:'1.5vw'}}>&gt; luckyonezine@gmail.com</span>
                </div>
            </div>
        </div>
    );
}

export default ContactUsPage;