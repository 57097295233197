import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Page4 from '../Assets/Page4.png';
import Page5 from '../Assets/Page5.png';
import Text1 from '../Assets/TextforPage4.png';
import Text2 from '../Assets/TextforPage4_2.png';
import Text3 from '../Assets/TextforPage5.png';
import Text4 from '../Assets/TextforPage5_2.png';  
import YellowBox1 from '../Assets/YellowBox1.png'
import YellowBox2 from '../Assets/YellowBox2.png'
import YellowBox3 from '../Assets/YellowBox3.png'
import YellowBox4 from '../Assets/YellowBox4.png'

const Page4and5Component = () => {
    const [visibleText, setVisibleText] = useState([false, false, false, false]);

    const handleClick = (index) => {
        setVisibleText(prev => {
            const newState = [...prev];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const textStyles = {
        position: 'absolute',
        width: '35%',
        height: 'auto',
        opacity: 0,
        transition: { type: 'spring', stiffness: 10, damping: 5 }
    };
    const boxStyles = {
        position: 'absolute',
        cursor: 'pointer',
        width: '26%',
        height: '13%',
        zIndex: 1000
    }
    
    const textVariants = {
        visible: { y: 0, opacity: 1 },
        hidden: { y: -100, opacity: 0 }
    };
    const boxVariants = {
        hover: { scale: 1.10 },
        initial: { scale: 1 }
    };

    const boxPositions = [
        { left: '13%', top: '14%' },
        { left: '15%', top: '54%' },
        { left: '66.5%', top: '14%' },
        { left: '65%', top: '47%' }
    ];

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div style={{ display: 'flex' }}>
                <img src={Page4} alt="Page 4" style={{ width: '50%', height: 'auto' }} />
                <img src={Page5} alt="Page 5" style={{ width: '50%', height: 'auto' }} />
            </div>
            
            {boxPositions.map((position, index) => (
                <motion.img
                    key={index}
                    src={[YellowBox1, YellowBox2, YellowBox3, YellowBox4][index]}
                    style={{ ...boxStyles, ...position }}
                    variants={boxVariants}
                    initial="initial"
                    whileHover="hover"
                    onClick={() => handleClick(index)}
                />
            ))}
            <motion.img src={Text1} alt="Text 1" style={{ ...textStyles, left: '7.5%', top: '30%' }} variants={textVariants} animate={visibleText[0] ? 'visible' : 'hidden'} />
            <motion.img src={Text2} alt="Text 2" style={{ ...textStyles, left: '7.5%', top: '70%' }} variants={textVariants} animate={visibleText[1] ? 'visible' : 'hidden'} />
            <motion.img src={Text3} alt="Text 3" style={{ ...textStyles, left: '58%', top: '30%' }} variants={textVariants} animate={visibleText[2] ? 'visible' : 'hidden'} />
            <motion.img src={Text4} alt="Text 4" style={{ ...textStyles, left: '58%', top: '61%' }} variants={textVariants} animate={visibleText[3] ? 'visible' : 'hidden'} />
            {/* 
            <motion.img src={YellowBox1} style={{...boxStyles, left: '13%', top: '14%', width: '27%', height: '15%'}}/>
            <motion.img src={YellowBox2} style={{...boxStyles, left: '15%', top: '54%', width: '25%', height: '13%'}}/>
            <motion.img src={YellowBox3} style={{...boxStyles,left: '66.5%', top: '14%', width: '27%', height: '13%'}}/>
            <motion.img src={YellowBox4} style={{...boxStyles, left: '65%', top: '47%', width: '27%', height: '13%'}}/>
            */}
        </div>
    );
};

export default Page4and5Component;
