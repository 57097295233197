import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Page8 from '../Assets/Page8.png';
import Page9 from '../Assets/Page9.png';
import Title from '../Assets/APlaceOfWorship.png';
import Page9Image from '../Assets/Page9Image.png';
import Page9Image2 from '../Assets/Page9Image2.png';
import Page8Image from '../Assets/Page8Image.png';
import Page8Image2 from '../Assets/Page8Image2.png';
import Page8Text from '../Assets/Page8Text.png';


const Page8and9Component = () => {
    const pathVariants = {
        hidden: {
          pathLength: 0,
          opacity: 0
        },
        visible: {
          pathLength: 1,
          opacity: 1,
          transition: {
            duration: 3, // Duration of the animation
            ease: "easeOut"
          }
        }
      };

    const controls = useAnimation();

    useEffect(() => {
        async function sequenceAnimations() {
            await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for the SVG animation
            controls.start({ opacity: 1, transition: { duration: 1 } });
        }

        sequenceAnimations();
    },  [controls]);

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            
            <div style={{ display: 'flex' }}>
                <img src={Page8} alt="Page 8" style={{ width: '50%', height: 'auto' }} />
                <img src={Page9} alt="Page 9" style={{ width: '50%', height: 'auto' }} />
                
            </div>
            
            
            <motion.img src={Title} alt="Title" initial={{ opacity: 0 }} animate={controls}
                style={{ position: 'absolute', zIndex: 0, width: '50%', top: '0%' }} />
            <motion.img src={Page9Image2} alt="Page9Image2" initial={{ opacity: 0 }} animate={controls}
                style={{ position: 'absolute', zIndex: 0, width: '50%', top: '0%', left: '50%' }} />
            <motion.img src={Page8Image} alt="Page8Image" initial={{ opacity: 0 }} animate={controls}
                style={{ position: 'absolute', zIndex: 0, width: '50%', top: '7%', left: '3%' }} />
            <motion.img src={Page8Image2} alt="Page8Image2" initial={{ opacity: 0 }} animate={controls}
                style={{ position: 'absolute', zIndex: 0, width: '65%', top: '52.5%', left: '-.5%' }} />
            <motion.img src={Page9Image} alt="Page9Image" initial={{ opacity: 0 }} animate={controls}
                style={{ position: 'absolute', zIndex: 0, width: '60%', top: '45%', left: '35%' }} />
            <motion.img src={Page8Text} alt="Page8Text" initial={{ opacity: 0 }} animate={controls}
                style={{ position: 'absolute', zIndex: 3, width: '50%', top: '0%', left: '0%' }} />
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 396 612" 
            style={{ position: 'absolute', top: '0', left: 0, width: '100%', height: '100%' }}>
                    <defs>
                        <style>
                        {`.cls-2 { fill: none; stroke: #f9bb65; stroke-miterlimit: 10; stroke-width: 2px; }`}
                        </style>
                    </defs>
                    <motion.path
                        d="M-191.56,239.28c6.17-28.66,27.73-52.23,53.16-66.82,25.43-14.58,54.57-21.26,83.48-26.15,28.91-4.89,58.23-8.21,86.24-16.86,15.57-4.81,31.15-11.59,42.1-23.66,10.95-12.07,16.19-30.48,9.09-45.14-3.69-7.61-11.14-13.91-19.6-14s-16.78,7.81-15.29,16.13c1.56,8.67,12.26,13.04,20.86,11.14,8.6-1.9,15.51-8.08,22.19-13.82C147.73,11.09,218.92-21.28,293.36-32.06"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                        className="cls-2"
                        style={{transform: 'translate(-50%, 0%)',zIndex: 5 }}
                    />
                    <motion.path
                        d="M-46.09,706.09c.87-46.19,38.29-85.33,81.18-102.51,42.89-17.18,90.5-16.84,136.69-15.77,23.92.55,48.66,1.08,70.75-8.1,22.14-9.2,40-28.22,47.78-50.9,7.79-22.68,5.38-48.65-6.45-69.51-8.46-14.92-27.14-28.02-41.86-19.21-13.85,8.29-11.35,31.67,2.16,40.51,13.51,8.84,32.25,5.21,45.33-4.26,13.08-9.47,21.79-23.64,30.3-37.36,8.51-13.72,17.56-27.76,31-36.7,29.48-19.6,69.02-8.64,101.61,5.21,32.58,13.85,68.15,30.34,101.92,19.73,33.99-10.68,55.3-51.17,44.86-85.23"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                        className="cls-2"
                        style={{transform: 'translate(50%, 0%)', zIndex: 5 }}
                    />
            </svg>

        </div>
    );
};

export default Page8and9Component;
