import {React, useState} from 'react';
import './AboutUsPage.css';

const AboutUsPage = () => {

    const [messages, setMessages] = useState(false);

    return (
        <div className="about-container">
            <div className="team-banner">LUCKY ONE TEAM</div>
            <div className="team-div">
                <div className={"team-box right-dir"} style={{borderBottom:'2px solid purple'}}>
                    <div className={messages ? 'display-box invisible' : 'display-box'} style={{textAlign: 'right'}}>
                        <div className='name-div' style={{color:"#FABB64", backgroundColor:'#D14160'}}>Courtney Te</div>
                        <p className='role' style={{color:"#D14160"}}>Creative Director</p>
                    </div>
                    <div className={messages ? "messages-box" : "messages-box invisible"}>
                        <div className="name-role-div">
                            <div className='name-div' style={{color:"#FABB64", backgroundColor:'#D14160', width:'40%', height: "100%", fontSize: '1.2em', padding:'0'}}>Courtney Te</div> 
                            <p style={{fontSize:'1.2em'}}>Creative Director</p>
                        </div>
                        <div className="paragraph-div">
                            <b>LUCKY ONE Zine has always been a joy to work on, especially since I get to do it with my closest friends!</b> Seeing the culmination of our efforts pay off into a zine and interactive website has been so rewarding, and I'm grateful for this opportunity to showcase the breadth of Asian identity through our submissions and the work of our talented LUCKY ONE team!
                        </div>
                    </div>
                </div>
                <div className="team-box left-dir" style={{borderBottom:'2px solid purple', borderLeft: '2px solid purple'}}>
                    <div className={messages ? ' display-box invisible' : 'display-box'} style={{textAlign: 'left'}}>
                        <div className='name-div' style={{color:'#FD724E', backgroundColor: '#5B2A86'}}>Rohan Nair</div>
                        <p className='role' style={{color:"#FD724E"}}>Web Development</p>  
                    </div>
                    <div className={messages ? "messages-box" : "messages-box invisible"}>
                        <div className="name-role-div">
                            <div className='name-div' style={{color:'#FD724E', backgroundColor: '#5B2A86', width:'40%', height: "100%", fontSize: '1.2em', padding:'0'}}>Rohan Nair</div>
                            <p style={{color:"#FD724E", fontSize: '1.2em'}}>Web Development</p>
                        </div>
                        <div className="paragraph-div">
                            <b>It’s been a great honor for me to have the opportunity to do what I love and witness my closest friends use their talents on this project.</b> We’ve poured our hearts into this thing, and if nothing else, my hope for this zine would be to flip the script on Asian American identity and the stories around us and show people — through not only our lens, but the lens of the creatives who’ve submitted their works — that we’re not confined to a single story.
                        </div>
                    </div> 
                </div>
                <div className="team-box right-dir">
                    <div className={messages ? ' display-box invisible' : 'display-box'} style={{textAlign: 'right'}}> 
                        <div className='name-div' style={{backgroundColor: '#FD724E', color: '#5B2A86'}}>Emily Richardson</div>
                        <p className='role' style={{color: '#5B2A86'}}>Communications Director</p> 
                    </div>
                    <div className={messages ? "messages-box" : "messages-box invisible"} style={{paddingTop:'4%'}}>
                        <div className="name-role-div">
                            <div className='name-div' style={{backgroundColor: '#FD724E', color: '#5B2A86', height: '100%', width:'50%', padding: '0', fontSize:'1.2em'}}>Emily Richardson</div>
                            <p style={{color: '#5B2A86', width: '50%', fontSize: '1.2em', textAlign:'center'}}>Communications Director</p> 
                        </div>
                        <div className="paragraph-div">
                            <b>Working with my closest friends on this project has shown me how identity stretches far beyond what’s tangible.</b> In so many ways, our identity is shaped by the moments we share with those in our lives. Seeing how our contributors’ identities manifest in their lives has been a gift. I hope you, too, can feel their sincerity and just how special these works are.
                        </div>
                    </div>
                </div>
                <div className="team-box left-dir" style={{borderLeft: '2px solid purple'}}>
                    <div className={messages ? ' display-box invisible' : 'display-box'} style={{textAlign: 'left'}}>
                        <div className='name-div' style={{backgroundColor: '#FABB64', color: '#5B2A86'}}>Zoraz Haseeb</div>
                        <p className='role' style={{color: '#5B2A86'}}>Web Development</p>
                    </div> 
                    <div className={messages ? "messages-box" : "messages-box invisible"} style={{paddingTop:'4%'}}>
                        <div className="name-role-div">
                            <div className='name-div' style={{backgroundColor: '#FABB64', color: '#5B2A86', fontSize:'1.2em', width: '40%', padding:'0', height: '100%'}}>Zoraz Haseeb</div>
                            <p style={{color: '#5B2A86', fontSize: '1.2em'}}>Web Development</p>
                        </div>
                        <div className="paragraph-div">
                            <b>Being a part of the LUCKY ONE team has been a special experience.</b> Not only are they my roommates but my best friends as well. Working on a project together to culminate our college education truly imbued the last semester with significance, and I trust that sentiment is expressed through our work.
                        </div>
                    </div>  
                </div>
            </div>
            <p style={{fontSize:'1.3em', color:'#5B2A86'}} onClick={() => {setMessages(!messages)}}>MESSAGES</p>
        </div>
    );
}


export default AboutUsPage;