import React, { forwardRef, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";
import './ZineModal.css';
import { motion, AnimatePresence } from "framer-motion";
import CoverPage from "../PageComponents/CoverPage";
import BackCover from "../PageComponents/BackCover";
import Page1Component from "../PageComponents/Page1";
import Page2and3Component from "../PageComponents/Page2&3";
import Page4and5Component from "../PageComponents/Page4&5";
import Page6Component from "../PageComponents/Page6";
import Page7Component from "../PageComponents/Page7";
import Page8and9Component from "../PageComponents/Page8&9";
import Page10and11Component from "../PageComponents/Page10&11";
import Page12Component from "../PageComponents/Page12";
import Page13Component from "../PageComponents/Page13";
import Page14and15Component from "../PageComponents/Page14&15";
import Page16and17Component from "../PageComponents/Page16&17";
import Page18and19Component from "../PageComponents/Page18&19";
import Page20and21Component from "../PageComponents/Page20&21";
import Page22Component from "../PageComponents/Page22";
import Page23Component from "../PageComponents/Page23";
import Page24and25Component from "../PageComponents/Page24&25";
import Page26Component from "../PageComponents/Page26";
import Page27Component from "../PageComponents/Page27";
import Page28Component from "../PageComponents/Page28";
import Page29Component from "../PageComponents/Page29";
import Page30Component from "../PageComponents/Page30";

const ZineModal = forwardRef(({ selectedIndex, maxWidth }, ref) => {
    const [display, setDisplay] = useState();

    useImperativeHandle(ref, () => ({
        openZineModal: () => setDisplay(true),
        closeZineModal: () => setDisplay(false),
    }));

    const close = () => setDisplay(false);

    const DefaultComponent = () => <div>Something went wrong!</div>;

    const renderContent = () => {
        switch (selectedIndex) {
            case 1:
                return <CoverPage />; // Component for cover page
            case 2:
                return <BackCover />; // Component for cover page
            case 3:
                return <Page1Component />
            case 4:
                return <Page2and3Component />
            case 5:
                return <Page2and3Component />
            case 6:
                return <Page4and5Component />
            case 7:
                return <Page4and5Component />
            case 8:
                return <Page6Component />
            case 9:
                return <Page7Component />
            case 10:
                return <Page8and9Component />
            case 11:
                return <Page8and9Component />
            case 12:
                return <Page10and11Component />
            case 13:
                return <Page10and11Component />
            case 14:
                return <Page12Component />
            case 15:
                return <Page13Component />
            case 16:
                return <Page14and15Component />
            case 17:
                return <Page14and15Component />
            case 18:
                return <Page16and17Component />
            case 19:
                return <Page16and17Component />
            case 20:
                return <Page18and19Component />
            case 21:
                return <Page18and19Component />
            case 22:
                return <Page20and21Component />
            case 23:
                return <Page20and21Component />
            case 24:
                return <Page22Component />
            case 25:
                return <Page23Component />
            case 26:
                return <Page24and25Component />
            case 27:
                return <Page24and25Component />
            case 28:
                return <Page26Component />
            case 29:
                return <Page27Component />
            case 30:
                return <Page28Component />
            case 31:
                return <Page29Component />
            case 32:
                return <Page30Component />
            // other cases for different pages
            default:
                return <DefaultComponent />; // A default or fallback component
        }
    };

    return ReactDOM.createPortal(
        <AnimatePresence>
            {display && (
                <div className="ZineModal-wrapper">
                    <motion.div 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.3 } }}
                        exit={{ opacity: 0, transition: { duration: 0.3 } }}
                        onClick={close} 
                        className="ZineModal-backdrop"
                    />
                    <motion.div
                        initial={{ scale: 0, x: "-50%", y: "-50%" }}
                        animate={{ scale: 1, x: "-50%", y: "-50%", transition: { duration: 0.3 } }}
                        exit={{ scale: 0, x: "-50%", y: "-50%", transition: { duration: 0.3 } }}
                        style={{maxWidth: maxWidth}}
                        className="ZineModal-box"
                    >
                        {renderContent()}
                    </motion.div>
                </div>
            )}
        </AnimatePresence>,
        document.getElementById("ZineModal-root")
    );
});

export default ZineModal;
