import React, { useState } from 'react';
import { motion } from 'framer-motion';

import Page14 from '../Assets/Page14.png';
import Page15 from '../Assets/Page15.png';
import Page14Image from '../Assets/Page14Image.png';
import Page15Text from '../Assets/Page15Text.png';


const Page14and15Component = () => {
    const pathVariants = {
        hidden: {
          pathLength: 0,
          opacity: 0
        },
        visible: {
          pathLength: 1,
          opacity: 1,
          transition: {
            duration: 3, // Duration of the animation
            ease: "easeOut"
          }
        }
      };
      const fadeInBounceVariants = {
        fadeIn: {
            opacity: [0, 1],  // Starts invisible and transitions to visible
            scale: 1,        // Starts slightly scaled down
            transition: {
                duration: 3,
                ease: "easeInOut"
            }
        },
        bounce: {
            scale: [1, 1.05, 1],  // Bounces between normal and slightly larger size
            transition: {
                repeat: Infinity,
                repeatType: "reverse",
                duration: 2,
                ease: "easeInOut"
            }
        }
        };
        const textAnimation = {
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            transition: {
                delay: 4, // Set this delay to after all other animations
                duration: 2
            }
        };
        const AnimatedPage14Image = ({ src, alt }) => {
            const [animationState, setAnimationState] = useState("fadeIn");
        
            return (
                <motion.img
                    src={src}
                    alt={alt}
                    initial={animationState}
                    animate={animationState}
                    variants={fadeInBounceVariants}
                    onAnimationComplete={() => {
                        if (animationState === "fadeIn") {
                            setAnimationState("bounce");  // Change state to bounce after fadeIn completes
                        }
                    }}
                    style={{
                        position: 'absolute',
                        width: '50%', // Adjust width as needed
                        top: '0%',  // Center vertically
                        left: '0%',
                        transform: 'translate(-50%, -50%)', // Center the image
                        zIndex: 50
                    }}
                />
            );
        };

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
           <div style={{ display: 'flex' }}>
                <img src={Page14} alt="Page 14" style={{ width: '50%', height: 'auto' }} />
                <img src={Page15} alt="Page 15" style={{ width: '50%', height: 'auto' }} />
            </div>
            <AnimatedPage14Image src={Page14Image} alt="Page 14 Image" />
            <motion.img
                src={Page15Text}
                alt="Bowl"
                style={{ position: 'absolute', width: '50%', left: '50%', top: '0%'}}
                {...textAnimation} // Adjust delay as necessary
            />
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 396 612" 
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
                <defs>
                    <style>
                        {`.cls-3 { fill: none; stroke: #f9bb65; stroke-miterlimit: 10; stroke-width: 2px; }`}
                        {`.cls-1 { mask: url(#mask); }`}
                    </style>
                    <mask id="mask" x="0" y="0" width="100%" height="100%" maskUnits="userSpaceOnUse">
                        <rect width="396" height="612" style={{ fill: '#FFFFFF' }} />
                    </mask>
                </defs>
                
                <motion.path
                        d="M-57.19,423.4c-10.78-30.72-14.75-63.96-13.15-96.58,2.28-46.37,15.32-91.68,33.01-134.6,4.52-10.96,9.48-22.01,17.54-30.71,8.06-8.69,19.82-14.77,31.57-13.18,11.75,1.58,22.33,12.55,20.9,24.32-1.76,14.41-21.2,21.89-33.78,14.65-12.58-7.24-16.95-24.46-12.46-38.26,4.48-13.8,16.01-24.33,28.73-31.33,12.72-7,26.8-11.04,40.39-16.13s27.14-11.52,37.01-22.16c15.08-16.24,19.24-41.74,10.09-61.93C93.51-2.71,71.61-16.4,49.45-15.77c-8.76-3.34-17.92-6.11-25.8-1.02C15.78-11.7,11.23-1.32,14.18,7.58c4.11,12.35,20.4,16.93,32.71,12.69s21.35-14.6,29.53-24.72,16.59-20.81,28.43-26.22c16.98-7.74,37.36-2.69,53.35,6.94,15.99,9.62,29.04,23.36,43.81,34.76,14.78,11.4,32.6,20.77,51.2,19.25s36.8-17.6,34.86-36.17c-1.94-18.56-30.4-29.14-40.79-13.64-8.12,12.11-.12,29.19,11.65,37.79,21.81,15.92,52.44,13.16,77.44,2.96,25-10.19,46.93-26.77,71.59-37.78,24.65-11.01,54.62-15.83,77.9-2.15,10.12,5.95,18.07,14.87,26.79,22.72,8.72,7.85,18.97,14.98,30.66,15.97s24.72-6.26,26.53-17.85c1.8-11.59-12.85-23.1-22.36-16.22-3.95,10.18,5.38,21.57,16.02,24,10.65,2.42,21.62-1.36,31.88-5.09,24.64-8.96,49.28-17.91,73.91-26.87"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                        className="cls-3"
                        style={{transform: 'translate(-50%, 0%)',zIndex: 5 }}
                    />
                <motion.path
                        d="M456,383.52c8.43-27.99,11.47-57.76,10.03-87.02-2.28-46.37-15.32-91.68-33.01-134.6-4.52-10.96-9.48-22.01-17.54-30.71-8.06-8.69-19.82-14.77-31.57-13.18-11.75,1.58-22.33,12.55-20.9,24.32,1.76,14.41,21.2,21.89,33.78,14.65s16.95-24.46,12.46-38.26c-4.48-13.8-16.01-24.33-28.73-31.33-12.72-7-26.8-11.04-40.39-16.13-13.59-5.09-27.14-11.52-37.01-22.16-15.08-16.24-19.24-41.74-10.09-61.93s31.05-33.88,53.21-33.25"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                        className="cls-3"
                        style={{transform: 'translate(50%, 0%)',zIndex: 5 }}
                    />
                
            </svg>
        </div>
    );
};
export default Page14and15Component;
