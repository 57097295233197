import React from 'react';
import { motion } from 'framer-motion';
import Page23 from '../Assets/Page23.png';
import Page23Image1 from '../Assets/Page23Image1.png';
import Page23Image2 from '../Assets/Page23Image2.png';


const Page23Component = () => {
    // Define the animation variants for the slam effect
    const slamVariants = {
        offscreen: {
            scale: 3,
        },
        onscreen: {
            scale: 1,
            transition: {
                type: "spring",
                stiffness: 160,
                damping: 20,
                mass: 2,
                duration: 4 // Duration of the animation in seconds
            }
        }
    };
    const slamVariant = {
        offscreen: {
            scale: 3,
        },
        onscreen: {
            scale: 1,
            transition: {
                type: "spring",
                stiffness: 260,
                damping: 20,
                mass: 1,
                delay: .25,
                duration: 4 // Duration of the animation in seconds
            }
        }
    };

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page23} alt="Page 23" style={{ width: '100%', height: 'auto' }} />
            <motion.img
                src={Page23Image1}
                alt="Image 1"
                style={{
                    position: 'absolute',
                    top: '0%', // Adjust position as needed
                    left: '0%', // Adjust position as needed
                    width: '100%', // Adjust size as needed
                    height: 'auto'
                }}
                variants={slamVariants}
                initial="offscreen"
                animate="onscreen"
            />
            <motion.img
                src={Page23Image2}
                alt="Image 2"
                style={{
                    position: 'absolute',
                    top: '0%', // Adjust position as needed
                    left: '0%', // Adjust position as needed
                    width: '100%', // Adjust size as needed
                    height: 'auto'
                }}
                variants={slamVariant}
                initial="offscreen"
                animate="onscreen"
            />
        </div>
    );
};

export default Page23Component;
