import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Page1 from '../Assets/Page1.svg'
import Page1Logo from '../Assets/Page1Logo.svg'

const Page1Component = () => {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(!isClicked);
        
        setTimeout(() => setIsClicked(false), 1000); 
    };

    const logoAnimation = {
        initial: { scale: 0 },
        animate: {
            scale: 1,
            transition: { type: 'spring', stiffness: 400, damping: 30, delay: 0.5 }
        },
        clicked: {
            scale: 1.2,
            rotate: [0, 10, -10, 0],
            transition: { type: 'spring', stiffness: 400, damping: 5 }
        }
    };

    
    const logoStyle = {
        position: 'absolute',
        top: '0%',  
        left: '0%', 
        transform: 'translate(-50%, -50%)',
        width: '100%',
        cursor: 'pointer'
    };
 
    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page1} alt="Page 1" style={{ width: '100%', height: 'auto' }} />
            <motion.img 
                src={Page1Logo} 
                alt="Page 1 Logo" 
                style={logoStyle}
                variants={logoAnimation}
                initial="initial"
                animate={isClicked ? 'clicked' : 'animate'}
                onClick={handleClick}
            />
        </div>
    );
};

export default Page1Component;
