import React, { useState } from 'react';
import CoverPage from '../Assets/CoverPage.png';
import Petal1 from '../Assets/Petal1.svg';
import Petal2 from '../Assets/Petal2.svg';
import Petal3 from '../Assets/Petal3.svg';
import Petal4 from '../Assets/Petal4.svg';
import Petal5 from '../Assets/Petal5.svg';
import './CoverPage.css';
import { motion } from 'framer-motion';
import Bell from '../Assets/Bell.png';

const CoverPage1 = () => {
  const [isPetalHovered, setIsPetalHovered] = useState(false);
  const [isPetalClicked, setIsPetalClicked] = useState(false);
  const [isBellHovered, setIsBellHovered] = useState(false);
  const [isBellClicked, setIsBellClicked] = useState(false);

  const handlePetalClick = () => {
    setIsPetalClicked(!isPetalClicked);
  };

  const petalAnimations = {
    petal1: isPetalClicked ? { translateX: 5, translateY: 5, rotate: 5 } : { translateX: 0, translateY: 0 }, 
    petal2: isPetalClicked ? { translateX: -5, translateY: -20, rotate: -5 } : { translateX: 0, translateY: 0 },
    petal3: isPetalClicked ? { translateX: 5, translateY: 5, rotate: 5 } : { translateX: 0, translateY: 0 },
    petal4: isPetalClicked ? { translateX: -5, translateY: -20, rotate: -5 } : { translateX: 0, translateY: 0 },
    petal5: isPetalClicked ? { rotate: 0 } : { rotate: 0 },
  };
  const bellAnimation = {
    bell: isBellClicked 
      ? { scale: 1, rotate: 360, transition: { type: 'spring', stiffness: 200 } }
      : { scale: 1, rotate: 0, transition: { type: 'spring', stiffness: 200 } },
  };

  const handleBellClick = () => {
    setIsBellClicked(!isBellClicked);
  };

  const petalStyle = isPetalHovered ? { filter: 'brightness(1.3)' } : {};
  const bellStyle = isBellHovered ? { filter: 'brightness(1.3)' } : {};
    return (
      <div style={{ position: 'relative' }}> 
        <div 
        className="bell-hover-area" 
        style={{ position: 'absolute',top: '49.3%', left: '46%', width: '8%', height: '5.4%',}}
        onMouseEnter={() => setIsBellHovered(true)} 
        onMouseLeave={() => setIsBellHovered(false)} 
        onClick={handleBellClick}
        />
        
        <img src={CoverPage} alt="Cover Page" style={{ width: '100%', height: 'auto' }} />
        <motion.img src={Petal5} alt="Petal 5" style={{ ...petalStyle, position: 'absolute', top: '-2%', left: '-3.5%' }} animate={petalAnimations.petal5}/>
        <motion.img src={Petal3} alt="Petal 3" style={{ ...petalStyle,position: 'absolute', top: '-1%', left: '-3%' }} animate={petalAnimations.petal3}/>
        <motion.img src={Petal1} alt="Petal 1" style={{ ...petalStyle,position: 'absolute', top: '-2%', left: '-4%' }} animate={petalAnimations.petal1}/>
        <motion.img src={Petal4} alt="Petal 4" style={{ ...petalStyle,position: 'absolute', top: '-1%', left: '-2.7%' }} animate={petalAnimations.petal4}/>
        <motion.img src={Petal2} alt="Petal 2" style={{ ...petalStyle,position: 'absolute', top: '-1.5%', left: '-3%' }} animate={petalAnimations.petal2}/>
        

        <motion.img src={Bell} alt="Bell" style={{ ...bellStyle ,position: 'absolute', width: '7.5%', height: 'auto', top: '49.3%', left: '46.2%'}}animate ={bellAnimation.bell}  />

        
        
        <div 
        className="flower-hover-area" 
        style={{ position: 'absolute', top: '44%', left: '17%', width: '18%', height: '9%' }}
        onMouseEnter={() => setIsPetalHovered(true)}
        onMouseLeave={() => setIsPetalHovered(false)}
        onClick={handlePetalClick}
        />
      </div>
    );
  };
  
  export default CoverPage1;
  