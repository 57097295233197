import {React} from 'react';
import './SubmissionsPage.css'

const SubmissionsPage = () => {
    return (
        <div className="submissions-page">
            <div className="submissions-container">
                <div className="submissions-header">
                    <span style={{fontFamily:'inglesa-script-variable'}}>S</span>UBMISSIONS
                </div>
                <div className="submissions-note">
                    <p>
                    <b>Thank you so much to everyone that submitted to LUCKY ONE!</b>
                    <br/><br/>
                    This wouldn’t have been possible without you and your wonderful work.
                    <br/><br/> 
                    This project was funded by a VCUArts Undergraduate Research Grant. Due to the fact that the entire team consists of seniors graduating in Spring 2024, LUCKY ONE will not be doing multiple print issues, and copies of the official zine will be limited. 
                    <br/><br/>
                    That being said, this website will serve as a place for the zine to live in a digital space and become publicly available.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SubmissionsPage;