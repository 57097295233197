import {React, useState} from 'react';
import LeftArrow from '../Assets/LeftArrow.svg';
import ArtistIndex from '../Assets/Artist_Index.svg'
import One from '../Assets/spread1.jpg';
import Two from '../Assets/spread2.jpg';
import Four from '../Assets/spread4.jpg';
import Five from '../Assets/spread5.jpg';
import Six from '../Assets/spread6.jpg';
import Seven from '../Assets/spread7.jpg';
import Eight from '../Assets/spread8.jpg';
import Nine from '../Assets/spread9.jpg';
import Ten from '../Assets/spread10.jpg';
import Eleven from '../Assets/spread11.jpg';
import Twelve from '../Assets/spread12.jpg';
import Thirteen from '../Assets/spread13.jpg';
import Fourteen from '../Assets/spread14.jpg';
import Fifteen from '../Assets/spread15.jpg';
import Sixteen from '../Assets/spread16.jpg';
import Seventeen from '../Assets/spread17.jpg';
import './ZinePage.css';

const pages = [
    {
        url: One,
        titleOne: '',
        titleTwo: '',
        artistOne: '',
        artistTwo: '',
        descOne: '',
        descTwo: ''

    },
    {
        url: Two,
        titleOne: '',
        titleTwo: '',
        artistOne: '',
        artistTwo: '',
        descOne: '',
        descTwo: ''

    },
    {
        url: Four,
        titleOne: '',
        titleTwo: '',
        artistOne: '',
        artistTwo: '',
        descOne: '',
        descTwo: ''
    },
    {
        url: Five,
        titleOne: '',
        titleTwo: '',
        artistOne: '',
        artistTwo: '',
        descOne: '',
        descTwo: ''
    },
    {
        url: Six,
        titleOne: 'A Place Of Worship (for whom?)',
        titleTwo: '',
        artistOne: 'Liana Tai',
        artistTwo: '',
        descOne: 'Liana Tai is a multimedia artist and creative writer, currently in her last semester at VCUarts as a craft/material studies major. Through her art, she dissects her queer, Asian-American diaspora to her home countries, as well as her connections to her ancestors. Currently, she has been selling her production art at local markets. She is a storyteller through many mediums. Her writing consists of poetry and personal essays about various topics ranging from cultural longings to the act of creating. Besides making, she enjoys spending time with her friends and cats.',
        descTwo: ''
    },
    {
        url: Seven,
        titleOne: 'When I Take Off',
        titleTwo: 'So Let Me Dream',
        artistOne: 'Rey Carlson',
        artistTwo: 'Christian Banez',
        descOne: 'Rey Carlson (they/them) is a Chinese designer, visual artist and writer. Their work often explores subjects such as queerness as refuge, Chinese mythology and storytelling, and loss. Rey\'s work centers queer alchemy as an interpersonal healing, intentional generosity over output, and always taking your shoes off at the door.',
        descTwo: 'Christian is a queer Filipino-American artist based in Brooklyn, NY. Born in the Philippines and raised in rural Missouri, he documents and takes inspiration from his everyday life and thoughts to inform an art practice dedicated to appreciating the "every day." '
    },
    {
        url: Eight,
        titleOne: 'Lucky Cat',
        titleTwo: 'Love',
        artistOne: 'Jack Congliaro',
        artistTwo: 'Liana Tai',
        descOne: '',
        descTwo: 'Liana Tai is a multimedia artist and creative writer, currently in her last semester at VCUarts as a craft/material studies major. Through her art, she dissects her queer, Asian-American diaspora to her home countries, as well as her connections to her ancestors. Currently, she has been selling her production art at local markets. She is a storyteller through many mediums. Her writing consists of poetry and personal essays about various topics ranging from cultural longings to the act of creating. Besides making, she enjoys spending time with her friends and cats.'
    },
    {
        url: Nine,
        titleOne: '',
        titleTwo: 'A Conversation With An Empty Room',
        artistOne: '',
        artistTwo: 'Andy Richardson',
        descOne: '',
        descTwo: ''
    },
    {
        url: Ten,
        titleOne: 'Instructions',
        titleTwo: '',
        artistOne: 'Liana Tai',
        artistTwo: '',
        descOne: 'Liana Tai is a multimedia artist and creative writer, currently in her last semester at VCUarts as a craft/material studies major. Through her art, she dissects her queer, Asian-American diaspora to her home countries, as well as her connections to her ancestors. Currently, she has been selling her production art at local markets. She is a storyteller through many mediums. Her writing consists of poetry and personal essays about various topics ranging from cultural longings to the act of creating. Besides making, she enjoys spending time with her friends and cats.',
        descTwo: ''
    },
    {
        url: Eleven,
        titleOne: 'Coexistence',
        titleTwo: '',
        artistOne: 'Liana Tai',
        artistTwo: '',
        descOne: 'Liana Tai is a multimedia artist and creative writer, currently in her last semester at VCUarts as a craft/material studies major. Through her art, she dissects her queer, Asian-American diaspora to her home countries, as well as her connections to her ancestors. Currently, she has been selling her production art at local markets. She is a storyteller through many mediums. Her writing consists of poetry and personal essays about various topics ranging from cultural longings to the act of creating. Besides making, she enjoys spending time with her friends and cats.',
        descTwo: ''
    },
    {
        url: Twelve,
        titleOne: 'Untitled',
        titleTwo: '',
        artistOne: 'Caitlyn Kantaparn',
        artistTwo: '',
        descOne: '',
        descTwo: ''
    },
    {
        url: Thirteen,
        titleOne: 'you are flâneur',
        titleTwo: '',
        artistOne: 'Daniel',
        artistTwo: '',
        descOne: 'Daniel (they/them) is a Chinese-American poet born and raise in the former confederate capital of Richmond, Virginia. They enjoy animation, orange chicken, and games of chance. ONe day, they hope to run a local community space for culture, art, and gathering. You can find them on all socials at @danielchow',
        descTwo: ''
    },
    {
        url: Fourteen,
        titleOne: 'Photos of Jincheng, Jinhu, and Jinsha Township + Jinshui Villiage and Jiofen',
        titleTwo: '',
        artistOne: 'Chia Yang',
        artistTwo: '',
        descOne: '',
        descTwo: ''
    },
    {
        url: Fifteen,
        titleOne: '',
        titleTwo: '',
        artistOne: '',
        artistTwo: '',
        descOne: '',
        descTwo: ''
    },
    {
        url: Sixteen,
        titleOne: '',
        titleTwo: '',
        artistOne: '',
        artistTwo: '',
        descOne: '',
        descTwo: ''
    },
    {
        url: Seventeen,
        titleOne: '',
        titleTwo: '',
        artistOne: '',
        artistTwo: '',
        descOne: '',
        descTwo: ''
    }
]

const ZinePage = () => {
    const [currIndex, setCurrIndex] = useState(0);

    const handleLeftClick = () => {
        setCurrIndex(currIndex - 1 < 0 ? 0 : currIndex - 1);
    }

    const handleRightClick = () => {
        setCurrIndex(currIndex + 1 > pages.length - 1 ? currIndex : currIndex + 1);
    }

    return (
        <div className="zine-page">
            <div className="carousel-container">
                <div className="zine-carousel">
                    <div className="left-arrow">
                        <img src={LeftArrow} alt="left" onClick={handleLeftClick}/>
                    </div>
                    <div className="carousel">
                        {pages.map((value, index) => (<img src={value.url} key={value.url} alt={value.artistOne} className={`zine-image`} style={{transform: `translateX(${-100 * currIndex}%)`}} loading="lazy"/>))}
                    </div>
                    <div className="right-arrow">
                        <img src={LeftArrow} alt="left" onClick={handleRightClick}/>
                    </div>
                </div>
                <div className="artist-index">
                    <div className="index">
                        <p style={{color:'#5B2A86'}}>{pages[currIndex].titleOne}</p>
                        <p style={{color: '#D14160'}}>{pages[currIndex].artistOne}</p>
                        <p style={{color:'#5B2A86', fontSize:'0.8em', overflow: 'auto', paddingRight: '5px'}}>{pages[currIndex].descOne}</p>
                    </div>
                    <div className="index right-align">
                        <p style={{color:'#5B2A86'}}>{pages[currIndex].titleTwo}</p>
                        <p style={{color: '#D14160'}}>{pages[currIndex].artistTwo}</p>
                        <p style={{color:'#5B2A86', fontSize:'0.8em', overflow: 'auto', direction: 'rtl', paddingLeft: '5px'}}>{pages[currIndex].descTwo}</p>
                    </div>
                </div>
            </div>
            <div className="index-container">
               <img src={ArtistIndex} alt="Artist Index"/> 
                <ol>
                    <li onClick={() => setCurrIndex(0)}>Introduction</li>
                    <li onClick={() => setCurrIndex(1)}>Topic</li>
                    <li onClick={() => setCurrIndex(2)}>Model Minority Myth Breakdown</li>
                    <li onClick={() => setCurrIndex(3)}>Breadth of Asian Identity</li>
                    <li onClick={() => setCurrIndex(4)}>A Place of Worship (for whom?)</li>
                    <li onClick={() => setCurrIndex(5)}>When I Take Off / So Let Me Dream</li>
                    <li onClick={() => setCurrIndex(6)}>Lucky Cat / Love</li>
                    <li onClick={() => setCurrIndex(7)}>A Coversation With An Empty Room</li>
                    <li onClick={() => setCurrIndex(8)}>Instructions</li>
                    <li onClick={() => setCurrIndex(9)}>Coexistence</li>
                    <li onClick={() => setCurrIndex(10)}>Elephants</li>
                    <li onClick={() => setCurrIndex(11)}>you are flâneur</li>
                    <li onClick={() => setCurrIndex(12)}>Photos</li>
                    <li onClick={() => setCurrIndex(13)}>Artist Index</li>
                    <li onClick={() => setCurrIndex(14)}>Meet the Team</li>
                    <li onClick={() => setCurrIndex(15)}>Back Cover</li> 
                </ol>

            </div>
        </div>
    );
}

export default ZinePage;