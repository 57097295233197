// Homepage.js
import React, { useState} from 'react';
import { Button } from '@mui/material';
import './HomePage.css';
import ExpandImage from '../Assets/Expand.svg';
import CoverPage from '../Assets/CoverPageThumbnail.png';
import BackCover from '../Assets/BackCoverThumbnail.png';
import Page1 from '../Assets/Page1Thumbnail.png'
import Page2 from '../Assets/Page2Thumbnail.png'
import Page3 from '../Assets/Page3Thumbnail.png'
import Page4 from '../Assets/Page4Thumbnail.png'
import Page5 from '../Assets/Page5Thumbnail.png'
import Page6 from '../Assets/Page6Thumbnail.png'
import Page7 from '../Assets/Page7Thumbnail.png'
import Page8 from '../Assets/Page8Thumbnail.png'
import Page9 from '../Assets/Page9Thumbnail.png'
import Page10 from '../Assets/Page10Thumbnail.png'
import Page11 from '../Assets/Page11Thumbnail.png'
import Page12 from '../Assets/Page12Thumbnail.png'
import Page13 from '../Assets/Page13Thumbnail.png'
import Page14 from '../Assets/Page14Thumbnail.png'
import Page15 from '../Assets/Page15Thumbnail.png'
import Page16 from '../Assets/Page16Thumbnail.png'
import Page17 from '../Assets/Page17Thumbnail.png'
import Page18 from '../Assets/Page18Thumbnail.png'
import Page19 from '../Assets/Page19Thumbnail.png'
import Page20 from '../Assets/Page20Thumbnail.png'
import Page21 from '../Assets/Page21Thumbnail.png'
import Page22 from '../Assets/Page22Thumbnail.png'
import Page23 from '../Assets/Page23Thumbnail.png'
import Page24 from '../Assets/Page24Thumbnail.png'
import Page25 from '../Assets/Page25Thumbnail.png'
import Page26 from '../Assets/Page26Thumbnail.png'
import Page27 from '../Assets/Page27Thumbnail.png'
import Page28 from '../Assets/Page28Thumbnail.png'
import Page29 from '../Assets/Page29Thumbnail.png'
import Page30 from '../Assets/Page30Thumbnail.png'
import ZineModal from '../ZineModal/ZineModal';


const buttonStyle = {
  color: '#5B2A86',
  borderColor: '#5B2A86',
  borderRadius: '15px',
  fontSize: '1.5em',
  textAlign: 'right',
  textTransform: 'none',
  width: 'fit-content',
  padding: '.001em .55em',
  marginRight: 0,
  fontFamily: "'gyst-variable', sans-serif"
};

const buttonStyle2 = {
  color: '#5B2A86',
  borderColor: '#5B2A86',
  borderRadius: '10px',
  fontSize: '1.5em',
  textAlign: 'right',
  textTransform: 'none',
  width: 'fit-content',
  padding: '.001em .55em',
  marginRight: 0,
  fontFamily: "'forma-djr-display', sans-serif"
};
const imageData = [
  {
    id: 1,
    thumbnail: CoverPage,
    title: 'Cover Page',
    artistName: '',
    description: ''
  },
  {
    id: 2,
    thumbnail: BackCover,
    title: 'Back Cover',
    artistName: '',
    description: ''
  },
  {
    id: 3,
    thumbnail: Page1,
    title: 'Page 1',
    artistName: '',
    description: ''
  },
  {
    id: 4,
    thumbnail: Page2,
    title: 'Page 2',
    artistName: '',
    description: ''
  },
  {
    id: 5,
    thumbnail: Page3,
    title: 'Page 3',
    artistName: '',
    description: ''
  },
  {
    id: 6,
    thumbnail: Page4,
    title: 'Page 4',
    artistName: '',
    description: ''
  },
  {
    id: 7,
    thumbnail: Page5,
    title: 'Page 5',
    artistName: '',
    description: ''
  },
  {
    id: 8,
    thumbnail: Page6,
    title: 'Page 6',
    artistName: '',
    description: ''
  },
  {
    id: 9,
    thumbnail: Page7,
    title: 'Page 7',
    artistName: '',
    description: ''
  },
  {
    id: 10,
    thumbnail: Page8,
    title: 'Page 8',
    artistName: '',
    description: ''
  },
  {
    id:11,
    thumbnail: Page9,
    title: 'Page 9',
    artistName: '',
    description: ''
  },
  {
    id:12,
    thumbnail: Page10,
    title: 'Page 10',
    artistName: '',
    description: ''
  },
  {
    id:13,
    thumbnail: Page11,
    title: 'Page 11',
    artistName: '',
    description: ''
  },
  {
    id:14,
    thumbnail: Page12,
    title: 'Page 12',
    artistName: '',
    description: ''
  },
  {
    id:15,
    thumbnail: Page13,
    title: 'Page 13',
    artistName: '',
    description: ''
  },
  {
    id:16,
    thumbnail: Page14,
    title: 'Page 14',
    artistName: '',
    description: ''
  },
  {
    id:17,
    thumbnail: Page15,
    title: 'Page 15',
    artistName: '',
    description: ''
  },
  {
    id:18,
    thumbnail: Page16,
    title: 'Page 16',
    artistName: '',
    description: ''
  },
  {
    id:19,
    thumbnail: Page17,
    title: 'Page 17',
    artistName: '',
    description: ''
  },
  {
    id:20,
    thumbnail: Page18,
    title: 'Page 18',
    artistName: '',
    description: ''
  },
  {
    id:21,
    thumbnail: Page19,
    title: 'Page 19',
    artistName: '',
    description: ''
  },
  {
    id:22,
    thumbnail: Page20,
    title: 'Page 20',
    artistName: '',
    description: ''
  },
  {
    id:23,
    thumbnail: Page21,
    title: 'Page 21',
    artistName: '',
    description: ''
  },
  {
    id:24,
    thumbnail: Page22,
    title: 'Page 22',
    artistName: '',
    description: ''
  },
  {
    id:25,
    thumbnail: Page23,
    title: 'Page 23',
    artistName: '',
    description: ''
  },
  {
    id:26,
    thumbnail: Page24,
    title: 'Page 24',
    artistName: '',
    description: ''
  },
  {
    id:27,
    thumbnail: Page25,
    title: 'Page 25',
    artistName: '',
    description: ''
  },
  {
    id:28,
    thumbnail: Page26,
    title: 'Page 26',
    artistName: '',
    description: ''
  },
  {
    id:29,
    thumbnail: Page27,
    title: 'Page 27',
    artistName: '',
    description: ''
  },
  {
    id:30,
    thumbnail: Page28,
    title: 'Page 28',
    artistName: '',
    description: ''
  },
  {
    id:31,
    thumbnail: Page29,
    title: 'Page 29',
    artistName: '',
    description: ''
  },
  {
    id:32,
    thumbnail: Page30,
    title: 'Page 30',
    artistName: '',
    description: ''
  },
  
];
const singlePageIndexes = [1,2, 3,8, 9, 14,15, 24, 25, 28, 29,30, 31,32];

function Homepage() {

  const [contentVisibility, setContentVisibility] = useState(true);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [message, setMessage] = useState('Constructing a reprieve from the\nnarrative of the asian model minority,\nLucky One aims to create a safe space\nto reshape what it means to be Asian today.');
  const ZineModalRef = React.useRef();

  const handleExpand = () => {
    setContentVisibility(!contentVisibility);
  }

  const handleZineItemClick = (index) => {
      setSelectedImageIndex(index);
      ZineModalRef.current.openZineModal();
  };

  return (
    <div className="homepage">
      
        <div className={contentVisibility ? `content-group` : `content-group invisible`}>
        <div className='content'>
          <p className="text">
            {message}
          </p>
          <div className="buttons">
            <Button variant="outlined" sx={buttonStyle} onClick={()=>setMessage('Constructing a reprieve from the\nnarrative of the asian model minority,\nLucky One aims to create a safe space\nto reshape what it means to be Asian today.')}>What is lucky one?</Button>
            <Button variant="outlined" sx={buttonStyle} onClick={()=>setMessage('The model minority myth paints Asian Americans as \npolite, law-abiding, innately successful, and intelligent.i\n The myth is used to downplay or deny the impact of systemic racism\n against BIPOC in the United States.')}>What is the model minority myth?</Button>
            <Button variant="outlined" sx={buttonStyle} onClick={()=>setMessage('We need to understand that the collective is important while \nindividual differences still exist and raise awareness of\n both experiences of various AAPI groups. We hope the variety of \nidentities and stories told in LUCKY ONE contribute to this effort.')}>How can I get involved?</Button>
          </div>
        </div>
        <div className= 'content2'>
          <div className="content-section">
            <Button variant="outlined" sx={buttonStyle2}>CHECK OUT OUR ZINE!</Button>
            <p className="text2">Based on our wonderful submissions for spring 2024!</p>
          </div>
          <div className="expand-image">
            <img onClick={handleExpand} src={ExpandImage} alt="expand" className="visible"/>
          </div>
        </div>
        </div>
        <div className={contentVisibility ? `zine-grid` : `zine-grid slide-up`}>
        {imageData.map(item => (
          <div className='zine-item' key={item.id} onClick={() => handleZineItemClick(item.id)}>
            <div className={`zine-pic ${!contentVisibility ? `zine-pic-animation` : ``}`} 
                 onMouseEnter={() => setHoveredItem(item.id)} 
                 onMouseLeave={() => setHoveredItem(null)}>
              <img src={item.thumbnail} alt={item.title} loading="lazy"/>
            </div>
            {hoveredItem === item.id && (
              <div className="zine-caption">
                <p>
                  <span style={{color:'#5B2A86'}}>{item.title}</span> <br />
                  <span style={{color:'#D14160'}}>{item.artistName}</span> <br />
                  <span style={{color:'#5B2A86', fontSize:'.8em'}}>{item.description}</span>
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
      <ZineModal 
        ref={ZineModalRef} 
        selectedIndex={selectedImageIndex} 
        maxWidth={singlePageIndexes.includes(selectedImageIndex) ? '45%' : '80%'}>
      </ZineModal>
      </div>
  );
}



export default Homepage;