import React from 'react';
import { motion } from 'framer-motion';

import Page13 from '../Assets/Page13.png';
import Bowl from '../Assets/Page13Bowl.png';
import Paintbrush from '../Assets/Page13Paintbrush.png';
import Napkin from '../Assets/Page13Napkin.png';
import Text from '../Assets/Page13Text.png';

const Page13Component = () => {
    const getLineAnimation = (delay) => ({
        initial: { pathLength: 0, opacity: 0 },
        animate: { pathLength: 1, opacity: 1 },
        transition: {
            pathLength: { type: "spring", duration: 3, bounce: 0 },
            opacity: { duration: 0.5 },
            delay: delay
        }
    });
    // Shared animation settings for moving items
    const itemAnimation = (delay) => ({
        initial: { y: -200, opacity: 0 }, // Start slightly above the final position
        animate: { y: 0, opacity: 1 },   // End at its final position
        transition: {
            delay: delay,
            duration: 4,
            type: "spring",
            stiffness: 120,
            damping: 12
        }
    });

    const textAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: {
            delay: 4, // Set this delay to after all other animations
            duration: 2
        }
    };

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page13} alt="Page 13" style={{ width: '100%', height: 'auto' }} />
            <motion.svg 
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 396 612"
                style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
            >
                <motion.line
                    x1="54.54" y1="67.91" x2="54.54" y2="294.03"
                    stroke="#d14160" strokeWidth="3" strokeLinecap="round"
                    {...getLineAnimation(3)}
                />
                <motion.line
                    x1="61.46" y1="67.91" x2="61.46" y2="343.95"
                    stroke="#d14160" strokeWidth="3" strokeLinecap="round"
                    {...getLineAnimation(6)}
                />
                <motion.line
                    x1="68.67" y1="67.91" x2="68.67" y2="392.16"
                    stroke="#d14160" strokeWidth="3" strokeLinecap="round"
                    {...getLineAnimation(9)}
                />  
            </motion.svg>
            <motion.img
                src={Bowl}
                alt="Bowl"
                style={{ position: 'absolute', width: '100%', left: '0%' }}
                {...itemAnimation(2)} // Adjust delay as necessary
            />
            <motion.img
                src={Paintbrush}
                alt="Paintbrush"
                style={{ position: 'absolute', width: '100%', left: '0%' }}
                {...itemAnimation(2.5)} // Slightly after the bowl
            />
            <motion.img
                src={Napkin}
                alt="Napkin"
                style={{ position: 'absolute', width: '100%', left: '0%' }}
                {...itemAnimation(3)} // Slightly after the paintbrush
            />
            <motion.img
                src={Text}
                alt="Text"
                style={{ position: 'absolute', width: '100%', left: '0%' }}
                {...textAnimation}
            />
        </div>
    );
};

export default Page13Component;
