import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Page2 from '../Assets/Page2.png';
import Page3 from '../Assets/Page3.png';
import and from '../Assets/&.png';

const Page2and3Component = () => {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(!isClicked);
    };

    const andAnimation = {
        animate: isClicked ? { rotate: 360 } : { rotate: 0 },
        transition: { type: 'spring', stiffness: 100, damping: 13 }
    };

    const andStyle = {
        position: 'absolute',
        top: '35%',  // Adjust as needed
        left: '40%', // Adjust as needed
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        width: '20%',
        height: 'auto'

    };

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div style={{ display: 'flex' }}> {/* Container with flex display */}
                <img src={Page2} alt="Page 2" style={{ width: '50%', height: 'auto' }} />
                <motion.img 
                    src={and} 
                    alt="And" 
                    style={andStyle} 
                    animate={andAnimation.animate} 
                    transition={andAnimation.transition}
                    onClick={handleClick} 
                />
                <img src={Page3} alt="Page 3" style={{ width: '50%', height: 'auto' }} />
            </div>
        </div>
    );
};

export default Page2and3Component;
