import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import './Navbar.css';
import Logo from '../Assets/Logo_group.svg';

function Navbar() {
  return (
    <AppBar position="static" className="navbar" sx={{ backgroundColor: '#FEF7EF', borderBottom: '2px solid #5B2A86' }} elevation={0}>
      <Toolbar className="toolbar" sx={{ height: '12vh' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center',width: '100%', margin: '0 3vw' }}>
          <Link to ="/" style={{width:'10%'}}><Button color="inherit" className="button" sx={{ color: '#5B2A86', fontSize: '1.15rem', width: '100%', borderRadius: '25px', textTransform: 'none', '&:hover': { borderColor: '#5B2A86', border: '1px solid' }, fontFamily: "'gyst-variable', sans-serif"}}>home</Button></Link>
          <Link to="/zine" style={{width:'10%'}}><Button color="inherit" className="button" sx={{ color: '#5B2A86', fontSize: '1.15rem', width: '100%', borderRadius: '25px', textTransform: 'none', '&:hover': { borderColor: '#5B2A86', border: '1px solid' } ,fontFamily: "'gyst-variable', sans-serif"}}>zine</Button></Link>
          <Link to="/aboutus" style={{width:'10%'}}><Button color="inherit" className="button" sx={{ color: '#5B2A86', fontSize: '1.15rem', width: '100%', borderRadius: '25px', textTransform: 'none','&:hover': { borderColor: '#5B2A86', border: '1px solid' },fontFamily: "'gyst-variable', sans-serif" }}>about us</Button></Link>
          <img src={Logo} alt="Logo" className="logo" />
          <Link to="/printissue" style={{width:'10%'}}><Button color="inherit" className="button" sx={{ color: '#5B2A86', fontSize: '1.15rem', width: '100%', borderRadius: '25px', textTransform: 'none','&:hover': { borderColor: '#5B2A86', border: '1px solid' },fontFamily: "'gyst-variable', sans-serif" }}>print issue</Button></Link>
          <Link to="/submissions" style={{width:'10%'}}><Button color="inherit" className="button" sx={{ color: '#5B2A86', fontSize: '1.15rem', width: '100%', borderRadius: '25px', textTransform: 'none','&:hover': { borderColor: '#5B2A86', border: '1px solid' },fontFamily: "'gyst-variable', sans-serif" }}>submissions</Button></Link>
          <Link to="/contact" style={{width:'10%'}}><Button color="inherit" className="button" sx={{ color: '#5B2A86', fontSize: '1.15rem', width: '100%', borderRadius: '25px', textTransform: 'none','&:hover': { borderColor: '#5B2A86', border: '1px solid' },fontFamily: "'gyst-variable', sans-serif" }}>contact us</Button></Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;