import React from 'react';
import { motion } from 'framer-motion';

import Page16 from '../Assets/Page16.png';
import Page17 from '../Assets/Page17.png';
import Page17Image from '../Assets/Page17Image.png';



const Page16and17Component = () => {
    const animationVariants = {
        animate: {
            scale: [1, 1.10, 1], // This will cause the element to scale up and down
            transition: {
                duration: 8, // Duration for one cycle
                repeat: Infinity, // Repeat indefinitely
                repeatType: "reverse", // Reverse the animation
                ease: "easeInOut" // Smooth transition
            }
        }
    };
    const pathVariants = {
        hidden: {
          pathLength: 0,
          opacity: 0
        },
        visible: {
          pathLength: 1,
          opacity: 1,
          transition: {
            duration: 3, // Duration of the animation
            ease: "easeOut"
          }
        }
      };
      const textAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: {
            delay: 3.5, // Set this delay to after all other animations
            duration: 2
        }
    };

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div style={{ display: 'flex' }}>
            <img src={Page16} alt="Page 16" style={{ width: '50%', height: 'auto' }} />
            <img src={Page17} alt="Page 17" style={{ width: '50%', height: 'auto' }} />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 612"
                style={{ position: 'absolute', top: '0', left: '25%', width: '100%', height: '100%' }}>
                <defs>
                    <style>
                        {`.cls-6 { fill: none; stroke: #d14160; stroke-miterlimit: 10; stroke-width: 2px; }`}
                    </style>
                </defs>
                <g>
                    <motion.polyline 
                        class="cls-6" 
                        points="92.63 425.58 92.63 47.32 345.03 47.32 345.03 422.85"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                    <motion.polyline 
                        class="cls-6" 
                        points="345.47 530.06 345.47 564.68 93.07 564.68 93.07 532.79"
                        variants={pathVariants}
                        initial="hidden"
                        animate="visible"
                    />
                </g>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 612"
                style={{ position: 'absolute', top: '0', left: '-25%', width: '100%', height: '100%' }}>
                <defs>
                    <style>
                    {`.cls-1 { fill: #fff }`}
                    {`.cls-2, .cls-3, .cls-4{ stroke-width: 0px; }`}
                    {`.cls-2 { fill: #d14160; }`}
                    {`.cls-3 { fill: #d14160; opacity: .8; }`}
                    {`.cls-4 { fill: #d14160; opacity: .6; }`}
                    {`.cls-5 { fill: #d14160; opacity: .4; }`}
                    </style>
                    <mask id="mask" x="-145.27" y="-37.27" width="686.53" height="686.53" maskUnits="userSpaceOnUse">
                        <rect class="cls-1" x="0" y=".36" width="396" height="612"/>
                    </mask>
                </defs>
                <g mask="url(#mask)">
                    <motion.path class="cls-2" d="M198,153.31c-84.33,0-152.69,68.36-152.69,152.69s68.36,152.69,152.69,152.69,152.69-68.36,152.69-152.69S282.33,153.31,198,153.31ZM198,443.47c-75.92,0-137.47-61.55-137.47-137.47S122.08,168.53,198,168.53s137.47,61.55,137.47,137.47-61.55,137.47-137.47,137.47Z"
                    variants={animationVariants}
                    animate="animate" />
                    <motion.path class="cls-3" d="M198,100.27C84.38,100.27-7.73,192.38-7.73,306s92.11,205.73,205.73,205.73,205.73-92.11,205.73-205.73-92.11-205.73-205.73-205.73ZM198,485.2c-98.97,0-179.2-80.23-179.2-179.2S99.03,126.8,198,126.8s179.2,80.23,179.2,179.2-80.23,179.2-179.2,179.2Z"
                    variants={animationVariants}
                    animate="animate" />
                    <motion.path class="cls-4" d="M198,32.64c-150.97,0-273.36,122.39-273.36,273.36s122.39,273.36,273.36,273.36,273.36-122.39,273.36-273.36S348.97,32.64,198,32.64ZM198,538.92c-128.64,0-232.92-104.28-232.92-232.92S69.36,73.08,198,73.08s232.92,104.28,232.92,232.92-104.28,232.92-232.92,232.92Z"
                    variants={animationVariants}
                    animate="animate" />
                    <motion.path class="cls-5" d="M198-37.27C8.42-37.27-145.27,116.42-145.27,306S8.42,649.27,198,649.27s343.27-153.69,343.27-343.27S387.58-37.27,198-37.27ZM198,608.5c-167.07,0-302.5-135.43-302.5-302.5S30.93,3.5,198,3.5s302.5,135.43,302.5,302.5-135.43,302.5-302.5,302.5Z"
                    variants={animationVariants}
                    animate="animate" />
                </g>
            </svg>
            <motion.img
                src={Page17Image}
                alt="Bowl"
                style={{ position: 'absolute', width: '50%', left: '50%', top: '0%'}}
                {...textAnimation} 
            />
            

        </div>
    );
};

export default Page16and17Component;
