import {React} from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from '../HomePage/HomePage.js';
import Navbar from '../Navbar/Navbar.js';
import ZinePage from '../ZinePage/ZinePage.js';
import AboutUsPage from '../AboutUsPage/AboutUsPage.js';
import PrintIssuePage from '../PrintIssuePage/PrintIssuePage.js';
import SubmissionsPage from '../SubmissionsPage/SubmissionsPage.js';
import ContactUsPage from '../ContactUsPage/ContactUsPage.js';

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="root">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/zine" element={<ZinePage />}></Route>
          <Route path="/aboutus" element={<AboutUsPage />}></Route>
          <Route path="/printissue" element={<PrintIssuePage />}></Route>
          <Route path ="/submissions" element={<SubmissionsPage />}></Route>
          <Route path="/contact" element={<ContactUsPage />}></Route>
        </Routes>
      </div>
      <div className="bottom">
      </div>
      <div className="vertical-line-left"></div>
      <div className="vertical-line-right"></div>
    </div>
  );
}

export default App;