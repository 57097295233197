import React from 'react';
import { motion } from 'framer-motion';
import Page6 from '../Assets/Page6.png';
import Cloud from '../Assets/Cloud.png';

const Page6Component = () => {
    // Animation settings for the cloud
    const cloudAnimation = {
        initial: { x: 0 },  // Start from slightly off-screen to the left
        animate: {
            x: [0, -50, 50, 0],  // Move to the center, then right, then left, then center again
            transition: {
                repeat: Infinity,  // Loop the animation
                repeatType: 'loop',
                duration: 4,  // Duration for one full cycle of back and forth
                ease: "linear"  // Use a linear animation timing
            }
        }
    };

    // Position and styling for the cloud
    const cloudStyle = {
        position: 'absolute',
        top: '33%',  // Position from the top
        left: '40%',  // Center horizontally
        transform: 'translateX(-50%)',  // Center the element by its middle
        width: '20%',  // Cloud width
        zIndex: 1000  // Ensure it is on top of the page image
    };

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page6} alt="Page 6" style={{ width: '100%', height: 'auto' }} />
            <motion.img
                src={Cloud}
                alt="Cloud"
                style={cloudStyle}
                variants={cloudAnimation}
                initial="initial"
                animate="animate"
            />
        </div>
    );
};

export default Page6Component;
