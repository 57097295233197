import React from 'react';
import { motion } from 'framer-motion';
import Page27 from '../Assets/Page27.png';
import Page27Pad1 from '../Assets/Page27Pad1.png';
import Page27Pad2 from '../Assets/Page27Pad2.png';
import Page27Pad3 from '../Assets/Page27Pad3.png';



const Page27Component = () => {
    const floatVariants = {
        initial: { y: "0%" },
        animate: {
            y: ["0%", "1%", "0%"],
            transition: {
                duration: 2.5,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop"
            }
        }
    };

    // Define a unique animation for Pad3
    const uniqueFloatVariants = {
        initial: { y: "0%" },
        animate: {
            y: ["0%", "-1%", "0%"],  // Opposite direction or different rhythm
            transition: {
                duration: 3,  // A slightly different duration
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop"
            }
        }
    };
    


    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page27} alt="Page 26" style={{ width: '100%', height: 'auto' }} />
            
            <motion.img
                src={Page27Pad1}
                alt="Pad 1"
                style={{
                    position: 'absolute',
                    top: '-1%', // Adjust these values as necessary
                    left: '0%',
                    width: '100%', // Size adjustments as necessary
                    height: 'auto'
                }}
                variants={floatVariants}
                initial="initial"
                animate="animate"
            />
            
            <motion.img
                src={Page27Pad2}
                alt="Pad 2"
                style={{
                    position: 'absolute',
                    top: '0%', // Adjust these values as necessary
                    left: '0%',
                    width: '100%', // Size adjustments as necessary
                    height: 'auto'
                }}
                variants={uniqueFloatVariants}
                initial="initial"
                animate="animate"
            />
            <motion.img
                src={Page27Pad3}
                alt="Pad 3"
                style={{
                    position: 'absolute',
                    top: '-1%', // Adjust these values as necessary
                    left: '0%',
                    width: '100%', // Size adjustments as necessary
                    height: 'auto'
                }}
                variants={floatVariants}
                initial="initial"
                animate="animate"
            />
        
            
        </div>
    );
};

export default Page27Component;
