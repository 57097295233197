import {React} from 'react';
import './PrintIssuePage.css';

const PrintIssuePage = () => {

    return (
        <div className="print-issue-container">
            <div className='info'>
                <div className="location-info">
                    <p style={{fontSize:'1.5em', color: '#FD724E'}}>MAY 3RD - MAY 10TH</p>
                    <p style={{fontSize:'2.5em', color: '#D14160', fontWeight: '700'}}>STUDIO TWO THREE</p>
                    <p style={{fontSize:'1.5em', color: '#5B2A86'}}>105 W 15TH St, Richmond, VA 23224</p>
                </div>
                <div className="specifics">
                    <p>70 copies will be available during the <b>GDES 2024 Senior Show</b> at Studio Two Three located in Richmond, VA. Copies are limited, so it’s best to show up the first few days of the show!</p>
                </div>
            </div>
            <div className='print-issue-heading'>
                <div className='print-issue'>
                   PRINT ISSUE
                </div>
                <div className='information'>
                    INFORMATIO<span style={{fontFamily:'inglesa-script-variable'}}>N</span>
                </div>
            </div>
        </div>
    );
}

export default PrintIssuePage;