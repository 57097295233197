import React from 'react';
import { motion } from 'framer-motion';
import Page24 from '../Assets/Page24.png';
import Page25 from '../Assets/Page25.png';
import Page24Shape from '../Assets/Page24Shape.png';
import Page25Shape from '../Assets/Page25Shape.png';


const Page24and25Component = () => {
    const fadeInVariants = {
        initial: {
            opacity: 0,
            
        },
        animate: {
            opacity: 1,
            
            transition: {
                duration: 4, // Duration of the fade-in effect
                ease: "easeInOut"
            }
        }
    };


    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div style={{ display: 'flex' }}>
                <img src={Page24} alt="Page 24" style={{ width: '50%', height: 'auto' }} />
                <img src={Page25} alt="Page 25" style={{ width: '50%', height: 'auto' }} />
            </div>
            <motion.img
                    src={Page24Shape}
                    alt="Shape on Page 24"
                    style={{
                        position: 'absolute',
                        top: '0%', // Adjust these values based on your layout
                        left: '0%', // Position over the page 24 image
                        width: '50%', // Size adjustments as necessary
                        height: 'auto'
                    }}
                    variants={fadeInVariants}
                    initial="initial"
                    animate="animate"
                />
                {/* Fade in shape for Page 25 */}
                <motion.img
                    src={Page25Shape}
                    alt="Shape on Page 25"
                    style={{
                        position: 'absolute',
                        top: '0%', // Adjust these values based on your layout
                        left: '50%', // Position over the page 25 image
                        width: '50%', // Size adjustments as necessary
                        height: 'auto'
                    }}
                    variants={fadeInVariants}
                    initial="initial"
                    animate="animate"
                />
        </div>
    );
};

export default Page24and25Component;
