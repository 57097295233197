import React from 'react';
import { motion } from 'framer-motion';
import Page18 from '../Assets/Page18.png';
import Page19 from '../Assets/Page19.png';
import Page18Image from '../Assets/Page18Image.png';
import Page18Text from '../Assets/Page18Text.png';
import Page19Image from '../Assets/Page19Image.png';




const Page18and19Component = () => {
    const containerVariants = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 1,  // Each child will appear 0.5 seconds after the previous
            }
        }
    };

    // Variants for each image
    const fadeInVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 2,  // Duration of the fade in
            }
        }
    };
    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div style={{ display: 'flex' }}>
                <img src={Page18} alt="Page 18" style={{ width: '50%', height: 'auto' }} />
                <img src={Page19} alt="Page 19" style={{ width: '50%', height: 'auto' }} />
            </div>
            <motion.div
                initial="hidden"
                animate="visible"
                variants={containerVariants}
                style={{ position: 'absolute', width: '100%', height: '100%', top: 0 }}
            >
                <motion.img src={Page18Image}
                    alt="Image"
                    variants={fadeInVariants}
                    style={{ position: 'absolute', width: '50%', left: '0%', top: '0%'}}
                /> 
                <motion.img src={Page18Text}
                    alt="Text"
                    variants={fadeInVariants}
                    style={{ position: 'absolute', width: '50%', left: '0%', top: '0%'}}
                /> 
                <motion.img src={Page19Image}
                    alt="Text"
                    variants={fadeInVariants}
                    style={{ position: 'absolute', width: '50%', left: '50%', top: '0%'}}
                />
            </motion.div>
               
            
        </div>
    );
};

export default Page18and19Component;
