import React from 'react';
import { motion } from 'framer-motion';
import Page28 from '../Assets/Page28.png';
import Page28Text from '../Assets/Page28Text.png';
import Page28Title from '../Assets/Page28Title.png';





const Page28Component = () => {
    const drawVariants = {
        initial: {
            pathLength: 0,
            opacity: 0
        },
        animate: {
            pathLength: 1,
            opacity: 1,
            transition: {
                duration: 4, // Duration of the draw effect
                ease: "easeInOut"
            }
        }
    };
    const fadeInVariants = {
        initial: {
            opacity: 0,
            
        },
        animate: {
            opacity: 1,
            
            transition: {
                duration: 4, // Duration of the fade-in effect
                ease: "easeInOut"
            }
        }
    };
    const fadeInVariant = {
        initial: {
            opacity: 0,
            
        },
        animate: {
            opacity: 1,
            
            transition: {
                delay: 4,
                duration: 2, // Duration of the fade-in effect
                ease: "easeInOut"
            }
        }
    };


    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page28} alt="Page 28" style={{ width: '100%', height: 'auto' }} />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 612" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: 'auto' }}>
                <defs>
                    <style>
                        {`.cls-2 { fill: none; stroke: #5b2d86; stroke-miterlimit: 10;stroke-width: 2px;}`}
                    </style>
                </defs>
                <motion.path
                    d="M 234.98 -13.86 H 327.09 V 625.85 H 234.98 V -13.86 Z"
                    className="cls-2"
                    variants={drawVariants}
                    initial="initial"
                    animate="animate"
                />
            </svg>
            <motion.img
                    src={Page28Text}
                    alt="Text on Page 28"
                    style={{
                        position: 'absolute',
                        top: '0%', // Adjust these values based on your layout
                        left: '0%', // Position over the page 24 image
                        width: '100%', // Size adjustments as necessary
                        height: 'auto'
                    }}
                    variants={fadeInVariants}
                    initial="initial"
                    animate="animate"
                />
            <motion.img
                    src={Page28Title}
                    alt="Title on Page 28"
                    style={{
                        position: 'absolute',
                        top: '0%', // Adjust these values based on your layout
                        left: '0%', // Position over the page 24 image
                        width: '100%', // Size adjustments as necessary
                        height: 'auto'
                    }}
                    variants={fadeInVariant}
                    initial="initial"
                    animate="animate"
                />
            
        </div>
    );
};

export default Page28Component;
