import React, { useState, useEffect  } from 'react';
import BackCover from '../Assets/BackCover.svg';
import Elephant from '../Assets/Elephant.png';
import { motion } from 'framer-motion';
import elephantSound from '../Assets/Elephant.mp3';

const BackCoverComponent = () => {
    const numElephants = 19;
    const [elephantsState, setElephantsState] = useState(Array(numElephants).fill({ hovered: false }));
    const [clickedElephant, setClickedElephant] = useState(null);
    const [audio] = useState(new Audio(elephantSound));

    const handleElephantHover = (index, isHovered) => {
      const newElephantsState = [...elephantsState];
      newElephantsState[index] = { ...newElephantsState[index], hovered: isHovered };
      setElephantsState(newElephantsState);
    };

    useEffect(() => {
      audio.volume = 0.1;
      return () => {
          if (audio) {
              audio.pause();
          }
      };
    }, [audio]);
  
  const handleElephantClick = (index) => {
    setClickedElephant(index);

    if (audio) {
        audio.pause();
        audio.currentTime = 0;
    }
    audio.play();

    setTimeout(() => setClickedElephant(null), 1000); 
};

    const elephantVariants = {
      hover: { scale: 1.2 },
      clicked: { scale: 1.2, rotate: [0, 10, -10, 10, -10, 0], transition: { duration: 0.5 } },
      normal: { scale: 1 },
  };

  
    const renderElephant = (index, style) => (
    <motion.img src={Elephant} 
                alt={`Elephant ${index + 1}`}
                style={{ ...style, position: 'absolute', cursor: 'pointer' }} 
                onMouseEnter={() => handleElephantHover(index, true)}
                onMouseLeave={() => handleElephantHover(index, false)}
                onClick={() => handleElephantClick(index)}
                animate={clickedElephant === index ? 'clicked' : elephantsState[index].hovered ? 'hover' : 'normal'}
                variants={elephantVariants} />
    );
    const elephantStyles = [
        { width: '25%',height: 'auto',top: '-7%', left: '-9%' },
        { width: '25%',height: 'auto',top: '21%', left: '-9%' },
        { width: '25%',height: 'auto',top: '52%', left: '-4%' },
        { width: '25%',height: 'auto',top: '80%', left: '-4%' },
        { width: '25%',height: 'auto',top: '8%', left: '12%'},  
        { width: '25%',height: 'auto',top: '-6%', left: '32%'},
        { width: '25%',height: 'auto',top: '38%', left: '17%' },
        { width: '25%',height: 'auto',top: '8%', left: '60%' },
        { width: '25%',height: 'auto',top: '-6%', left: '80%' },
        { width: '25%',height: 'auto',top: '53%', left: '38%' },
        { width: '25%',height: 'auto',top: '38.5%', left: '60%'},
        { width: '25%',height: 'auto',top: '24%', left: '80%' },
        { width: '25%',height: 'auto',top: '85%', left: '38%' },
        { width: '25%',height: 'auto',top: '24%', left: '38%' },
        { width: '25%',height: 'auto',top: '71%', left: '60%' },
        { width: '25%',height: 'auto',top: '57%', left: '80%' },
        { width: '25%',height: 'auto',top: '85%', left: '80%' },
        { width: '25%',height: 'auto',top: '98%', left: '17%' },
        { width: '25%',height: 'auto',top: '67%', left: '17%' },

    ];

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
        <img src={BackCover} alt="Back Cover" style={{ width: '100%', height: 'auto' }} />
        {elephantStyles.map((style, index) => renderElephant(index, style))}
    </div>
  );
};

export default BackCoverComponent;
