import React from 'react';
import Page30 from '../Assets/Page30Thumbnail.png';





const Page30Component = () => {
    
    
    


    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page30} alt="Page 30" style={{ width: '100%', height: 'auto' }} />
            
        </div>
    );
};

export default Page30Component;
