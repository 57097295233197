import React, { useState } from 'react';
import { motion } from 'framer-motion';

import Page12 from '../Assets/Page12.png';
import ChineseText1 from '../Assets/Chinese_Text1.png';
import ChineseText2 from '../Assets/Chinese_Text2.png';
import ChineseText3 from '../Assets/Chinese_Text3.png';
import ChineseText4 from '../Assets/Chinese_Text4.png';
import ChineseText5 from '../Assets/Chinese_Text5.png';

const textElements = [
    { src: ChineseText1, alt: 'Chinese Text 1', top: '47%', left: '39.25%' },
    { src: ChineseText2, alt: 'Chinese Text 2', top: '40%', left: '39%' },
    { src: ChineseText3, alt: 'Chinese Text 3', top: '33%', left: '39.25%' },
    { src: ChineseText4, alt: 'Chinese Text 4', top: '53%', left: '39.25%' },
    { src: ChineseText5, alt: 'Chinese Text 5', top: '60%', left: '39.25%' }
];

const Page12Component = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const url = "https://drive.google.com/file/d/1rN275_iZfOS4i6fkvfvibVmXUUODr1OT/view?usp=sharing";

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page12} alt="Page 12" style={{ width: '100%', height: 'auto' }} />
            {textElements.map((text, index) => (
                <a 
                    key={index}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ position: 'absolute', top: text.top, left: text.left, width: '20%', cursor: 'pointer' }}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                >
                    <motion.img 
                        src={text.src} 
                        alt={text.alt} 
                        style={{ width: '100%', height: 'auto' }} 
                        whileHover={{ scale: 1.5 }}
                        whileTap={{ scale: 0.9 }}
                    />
                    {hoveredIndex === index && (
                        <div style={{ position: 'absolute', width: '100%', top: '101%', color: 'white', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                            Click me!
                        </div>
                    )}
                </a>
            ))}
        </div>
    );
};

export default Page12Component;
