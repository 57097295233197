import React from 'react';
import { motion } from 'framer-motion';
import Page26 from '../Assets/Page26.png';
import Page26Title from '../Assets/Page26Title.png';
import Page26Text1 from '../Assets/Page26Text1.png';
import Page26Text2 from '../Assets/Page26Text2.png';
import Page26Text3 from '../Assets/Page26Text3.png';
import Page26Text4 from '../Assets/Page26Text4.png';
import Page26Text5 from '../Assets/Page26Text5.png';
import Page26Text6 from '../Assets/Page26Text6.png';
import Page26Text7 from '../Assets/Page26Text7.png';
import Page26Text8 from '../Assets/Page26Text8.png';



const Page26Component = () => {
    const containerVariants = {
        initial: {},
        animate: {
            transition: {
                staggerChildren: .5, // Stagger the animation of child elements by 0.5 seconds
            }
        }
    };
    const fadeInVariants = {
        initial: {
            opacity: 0,
            
        },
        animate: {
            opacity: 1,
            
            transition: {
                duration: 1, // Duration of the fade-in effect
                ease: "easeInOut"
            }
        }
    };


    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page26} alt="Page 26" style={{ width: '100%', height: 'auto' }} />
            <motion.div
                variants={containerVariants}
                initial="initial"
                animate="animate"
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: 'auto' }}
            >
                <motion.img
                    src={Page26Title}
                    alt="Title on Page 26"
                    style={{ position: 'absolute',width: '100%', height: 'auto' }}
                    variants={fadeInVariants}
                />
                <motion.img
                    src={Page26Text1}
                    alt="Text on Page 26"
                    style={{ position: 'absolute',width: '100%', height: 'auto' }}
                    variants={fadeInVariants}
                />
                <motion.img
                    src={Page26Text2}
                    alt="Text on Page 26"
                    style={{ position: 'absolute',width: '100%', height: 'auto' }}
                    variants={fadeInVariants}
                />
                <motion.img
                    src={Page26Text3}
                    alt="Text on Page 26"
                    style={{ position: 'absolute',width: '100%', height: 'auto' }}
                    variants={fadeInVariants}
                />
                <motion.img
                    src={Page26Text4}
                    alt="Text on Page 26"
                    style={{ position: 'absolute',width: '100%', height: 'auto' }}
                    variants={fadeInVariants}
                />
                <motion.img
                    src={Page26Text5}
                    alt="Text on Page 26"
                    style={{ position: 'absolute',width: '100%', height: 'auto' }}
                    variants={fadeInVariants}
                />
                <motion.img
                    src={Page26Text6}
                    alt="Text on Page 26"
                    style={{ position: 'absolute',width: '100%', height: 'auto' }}
                    variants={fadeInVariants}
                />
                <motion.img
                    src={Page26Text7}
                    alt="Text on Page 26"
                    style={{ position: 'absolute',width: '100%', height: 'auto' }}
                    variants={fadeInVariants}
                />
                <motion.img
                    src={Page26Text8}
                    alt="Text on Page 26"
                    style={{ position: 'absolute',width: '100%', height: 'auto' }}
                    variants={fadeInVariants}
                />
            </motion.div>
            
        </div>
    );
};

export default Page26Component;
