import React from 'react';
import { motion} from 'framer-motion';
import Page10 from '../Assets/Page10.png';
import Page11 from '../Assets/Page11.png';
import Page10Text from '../Assets/Page10Text.png'
import Page11Image from '../Assets/Page11Image.png'

import Page10PurpleLineComponent from './Page10Components/Page10PurpleLine';
import Page11YellowLineComponent from './Page11Components/Page11YellowLine';
import Page10Border from '../Assets/Page10Border.svg'
import Page11Border from '../Assets/Page11Border.svg'

const Page10and11Component = () => {
    const fadeInVariants = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1,
            transition: {
                delay: .5,
                duration: 2, // Duration of the fade-in effect
                ease: "easeInOut"
            }
        }
    };
      

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div style={{ display: 'flex' }}>
                <img src={Page10} alt="Page 10" style={{ width: '50%', height: 'auto' }} />
                <img src={Page11} alt="Page 11" style={{ width: '50%', height: 'auto' }} />
            </div>
            <motion.img 
                src={Page10Border} 
                alt="Page 10 Border" 
                style={{ position: 'absolute', width: '50%', height: 'auto', top: 0, left: 0 }}
                variants={fadeInVariants}
                initial="initial"
                animate="animate"
            />
            <motion.img 
                src={Page11Border} 
                alt="Page 11 Border" 
                style={{ position: 'absolute', width: '50%', height: 'auto', top: 0, left: '50%' }}
                variants={fadeInVariants}
                initial="initial"
                animate="animate"
            />
            <motion.img 
              src={Page10Text} 
              alt="Page 10 Text" 
              style={{ position: 'absolute', width: '50%', top: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                  delay: 3,
                  type: 'spring', // Specifies the type of transition
                  stiffness: 50,  // How stiff the spring should be
                  damping: 20     // How much damping there should be
              }}
            />
            <motion.img 
              src={Page11Image} 
              alt="Page 11 Image" 
              style={{ position: 'absolute', width: '50%', left: '50%', top: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                  delay: 3,
                  type: 'spring', // Specifies the type of transition
                  stiffness: 50,  // How stiff the spring should be
                  damping: 20     // How much damping there should be
              }}
            />

            
            
            
            
            <Page10PurpleLineComponent />
            <Page11YellowLineComponent />
            {/** */}
            
            
                    
            
        </div>
    );
};

export default Page10and11Component;
