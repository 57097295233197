import React from 'react';
import Page29 from '../Assets/Page29Thumbnail.png';





const Page29Component = () => {
    
    
    


    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <img src={Page29} alt="Page 29" style={{ width: '100%', height: 'auto' }} />
            
        </div>
    );
};

export default Page29Component;
